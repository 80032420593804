import styled from 'styled-components';
import { MenuItem } from './MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AtadenLogo, Colors } from '@ataden/design-system';
import { RoutePath } from '../../AuthenticatedRouter';
import { useGetCurrentUser } from '../../users/repositories/UserRepository';
import { useEffect, useState } from 'react';
import {
  USER_ROLE_ALL,
  USER_ROLE_DEV,
  USER_ROLE_FINANCE,
  USER_ROLE_MARKETING,
  USER_ROLE_OPS,
  USER_ROLE_SALE,
  USER_ROLE_SUPPORT,
} from '../../users/domain/User';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 230px;
    height: 100vh;
    background-color: ${Colors.Gray000};
    padding: 12px;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 16px 0;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const MiddleContainer = styled.div`
    margin-top: 36px;
    height: 100%;
`;

const BottomContainer = styled.div`
    margin-bottom: 32px;
`;

export const NavigationMenu = () => {
  const logoutRedirection = process.env.REACT_APP__AUTH__LOGOUT!;

  const navigate = useNavigate();
  const { logout } = useAuth0();
  const getCurrentUser = useGetCurrentUser();

  const [role, updateRole] = useState<number>(0);

  useEffect(() => {
    getCurrentUser()
      .then((user) => updateRole(user.role));
  }, []);

  const onLogOut = () => {
    logout({ logoutParams: { returnTo: logoutRedirection } });
  };

  const onProjects = () => {
    navigate(RoutePath.projects);
  };

  const onUsers = () => {
    navigate(RoutePath.users);
  };

  const onEvents = () => {
    navigate(RoutePath.events);
  };

  const onLogs = () => {
    navigate(RoutePath.logs);
  };

  const onIdentity = () => {
    navigate(RoutePath.identity);
  };

  const onCompanyIdentity = () => {
    navigate(RoutePath.companyIdentity);
  };

  const onDomains = () => {
    navigate(RoutePath.domains);
  };

  const onServers = () => {
    navigate(RoutePath.servers);
  };

  const onSimulation = () => {
    navigate(RoutePath.simulator);
  };

  return (
        <Container>
            <LogoContainer>
                <AtadenLogo width="120px" height="40px"/>
            </LogoContainer>
            <ItemsContainer>
                <MiddleContainer>
                    <MenuItem title="Projects" onClick={onProjects} currentUserRole={role}
                              requiredRole={USER_ROLE_DEV + USER_ROLE_SUPPORT + USER_ROLE_MARKETING}/>
                    <MenuItem title="Users" onClick={onUsers} currentUserRole={role}
                              requiredRole={USER_ROLE_DEV + USER_ROLE_SUPPORT + USER_ROLE_MARKETING + USER_ROLE_SALE}/>
                    <MenuItem title="Events" onClick={onEvents} currentUserRole={role}
                              requiredRole={USER_ROLE_SUPPORT + USER_ROLE_MARKETING + USER_ROLE_DEV}/>
                    <MenuItem title="Logs" onClick={onLogs} currentUserRole={role}
                              requiredRole={USER_ROLE_OPS + USER_ROLE_DEV + USER_ROLE_SUPPORT}/>
                    <MenuItem title="Identity" onClick={onIdentity} currentUserRole={role}
                              requiredRole={USER_ROLE_ALL}/>
                    <MenuItem title="Company Identity" onClick={onCompanyIdentity} currentUserRole={role}
                              requiredRole={USER_ROLE_MARKETING + USER_ROLE_SALE}/>
                    <MenuItem title="Simulation" onClick={onSimulation} currentUserRole={role}
                              requiredRole={USER_ROLE_SALE + USER_ROLE_FINANCE}/>
                    <MenuItem title="Domains" onClick={onDomains} currentUserRole={role} requiredRole={USER_ROLE_OPS}/>
                    <MenuItem title="Servers" onClick={onServers} currentUserRole={role} requiredRole={USER_ROLE_OPS}/>
                </MiddleContainer>
                <BottomContainer>
                    <MenuItem title="Log out" onClick={onLogOut} currentUserRole={role}/>
                </BottomContainer>
            </ItemsContainer>
        </Container>
  );
};
