import { controllerAxiosInstance } from '../../common';
import { useAuthenticatedGet, useAuthenticatedPost, useAuthenticatedPut } from '../../common/AuthenticatedRestCall.hook';
import { Server, ServerStatus } from '../domain/Server';
import { Package } from '../domain/Package';
import { useCallback } from 'react';
import { DiskUsage } from '../domain/DiskUsage';
import { Datacenter } from '../domain/Datacenter';
import { CpuAndMemoryUsage } from '../domain/CpuAndMemoryUsage';

type RestServerToCreate = {
  hostname: string;
  datacenter: Datacenter;
  ip: string;
};

type RestServer = {
  serverId: string;
  hostname: string;
  ip: string;
  datacenter: string;
  status: ServerStatus;
  notificationEnabled: boolean;
};

type RestPackage = {
  version: string;
};

type RestDiskUsage = {
  capacity: number;
  used: number;
  mount: string;
};

type RestMemoryUsage = {
  total: number;
  used: number;
  free: number;
  available: number;
  swap: number;
};

type RestCpuAndMemoryUsage = {
  cpuUsage: number;
  memoryUsage: RestMemoryUsage;
};


const restServerConverter = (servers: RestServer[]) => {
  return servers.map(s => {
    return { ...s };
  });
};

const restPackageConverter = (packages: RestPackage[]) => {
  return packages.map(s => {
    return { ...s };
  });
};

const restDiskUsageConverter = (diskUSages: RestDiskUsage[]) => {
  return diskUSages.map(s => {
    return { ...s };
  });
};

const restCpuAndMemoryUsageConverter = (cpuAndMemoryUsage: RestCpuAndMemoryUsage): CpuAndMemoryUsage => {
  return { ...cpuAndMemoryUsage };
};

export const useGetServers = () => {
  const authenticatedGet = useAuthenticatedGet<RestServer[], Server[]>(controllerAxiosInstance, restServerConverter);
  return useCallback(() => authenticatedGet('/backoffice/api/v1/topology/servers'), [authenticatedGet]);
};

export const useGetServerDiskUsages = () => {
  const authenticatedGet = useAuthenticatedGet<RestDiskUsage[], DiskUsage[]>(controllerAxiosInstance, restDiskUsageConverter);
  return useCallback((serverId: string) => authenticatedGet('/backoffice/api/v1/topology/servers/' + serverId + '/disks'), [authenticatedGet]);
};

export const useGetServerCpuAndMemoryUsages = () => {
  const authenticatedGet = useAuthenticatedGet<RestCpuAndMemoryUsage, CpuAndMemoryUsage>(controllerAxiosInstance, restCpuAndMemoryUsageConverter);
  return useCallback((serverId: string) => authenticatedGet('/backoffice/api/v1/topology/servers/' + serverId + '/cpu-and-memory'), [authenticatedGet]);
};

const filterWebVersion = (serviceType: string, version: string): boolean => {
  if (serviceType.toLowerCase().startsWith('web_')) {
    return version.endsWith('-' + process.env.REACT_APP__ENV);
  }
  return true;
};

export const useGetServicePackages = () => {
  const authenticatedGet = useAuthenticatedGet<RestPackage[], Package[]>(controllerAxiosInstance, restPackageConverter);
  return useCallback((serviceType: string) => {
    return new Promise<Package[]>((success, failure) => {
      authenticatedGet('/private/api/v1/services/' + serviceType + '/packages')
        .then((result) => {
          success(result
            .filter((a) => !a.version.startsWith('main-'))
            .filter((a) => filterWebVersion(serviceType, a.version))
            .sort((a, b) => b.version.localeCompare(a.version)));
        })
        .catch(failure);
    });
  }, [authenticatedGet]);
};

export const useUpgradeServicePackage = () => {
  const authenticatedPost = useAuthenticatedPost<void, void>(controllerAxiosInstance, () => {
  });
  return useCallback((serverId: string, serviceType: string, version: string) => authenticatedPost('/backoffice/api/v1/topology/servers/' + serverId + '/apps/' + serviceType + '/update/' + version, undefined), [authenticatedPost]);
};

export const useInstallServicePackage = () => {
  const authenticatedPost = useAuthenticatedPost<void, void>(controllerAxiosInstance, () => {
  });
  return useCallback((serverId: string, serviceType: string, version: string) => authenticatedPost('/backoffice/api/v1/topology/servers/' + serverId + '/apps/' + serviceType + '/install/' + version, undefined), [authenticatedPost]);
};

export const useRegisterServer = () => {
  const authenticatedPost = useAuthenticatedPost<string, string>(controllerAxiosInstance, (result) => result);
  return useCallback((hostname: string, datacenter: Datacenter, serverIp: string) => authenticatedPost('/backoffice/api/v1/topology/servers', {
    hostname: hostname,
    datacenter: datacenter,
    ip: serverIp,
  } as RestServerToCreate), [authenticatedPost]);
};

export const useRegisterService = () => {
  const authenticatedPost = useAuthenticatedPost<string, string>(controllerAxiosInstance, (result) => result);
  return useCallback((serverId: string, serviceType: string) => authenticatedPost('/backoffice/api/v1/topology/servers/' + serverId + '/apps/' + serviceType + '/register', {}), [authenticatedPost]);
};

export const usePrepareAgentInstall = () => {
  const authenticatedGet = useAuthenticatedGet<string, string>(controllerAxiosInstance, (result) => result);
  return useCallback((serverId: string) => authenticatedGet('/backoffice/api/v1/topology/servers/' + serverId + '/apps/AGENT/prepare-install'), [authenticatedGet]);
};
 
export const useUpdateServerNotification = () => {
  const authenticatedPost = useAuthenticatedPut<string, string>(controllerAxiosInstance, (result) => result);
  return useCallback((serverId: string, notificationEnabled: boolean) => authenticatedPost('/backoffice/api/v1/topology/servers/' + serverId + '/notifications', {
    notificationEnabled: notificationEnabled,
  }), [authenticatedPost]);
};
