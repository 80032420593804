import { Server } from '../domain/Server';
import { AtdnCopyButton, AtdnText } from '@ataden/design-system';
import React from 'react';
import { InfoContainer, ItemContainer, LabelContainer } from '../../common/components/PanelItem.shared';
import styled from 'styled-components';

type ServerInfoComponentProps = {
  server: Server;
};

const InfoWithCopy = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const computeBastion = (datacenter: string): string => {
  if (datacenter === 'HOME_01') {
    return '82.64.75.157:22222';
  } else if (datacenter === 'HOME_02') {
    return '88.169.215.32:22222';
  } else if (datacenter === 'SCALEWAY_PAR1') {
    return '212.47.234.123:61000';
  } else if (datacenter === 'LYON_01') {
    return '31.7.252.101:22222';
  }
  return '0.0.0.0';
};

export const ServerInfoComponent = ({ server }: ServerInfoComponentProps) => {

  const bastion = computeBastion(server.datacenter);

  const sshText = 'ssh -J bastion@' + bastion + ' ataden@' + server.ip;

  return (
        <>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">id:</AtdnText>
                        <InfoWithCopy>
                            <AtdnText>{server.serverId}</AtdnText>
                            <AtdnCopyButton textToCopy={server.serverId}
                                            tooltipToCopy="Copy server ID"
                                            tooltipCopied="Copied"/>
                        </InfoWithCopy>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">sh:</AtdnText>
                        <InfoWithCopy>
                            <AtdnText>{sshText}</AtdnText>
                            <AtdnCopyButton textToCopy={sshText}
                                            tooltipToCopy="Copy SSH command"
                                            tooltipCopied="Copied"/>
                        </InfoWithCopy>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
        </>
  );
};
