import { useAuthenticatedGet, useAuthenticatedPost } from '../../common/AuthenticatedRestCall.hook';
import { atadenAxiosInstance, Page, RestPage } from '../../common';
import { useCallback } from 'react';
import { Project } from '../domain/Project';

type RestProject = {
  id: string;
  name: string;
  createdDate: number;
  spaceQuotaLimit: number;
  s3DomainStatus: string;
};

const restProjectConverter = (project: RestProject): Project => {
  return { ...project };
};

const restPageProjectConverter = (projects: RestPage<RestProject>): Page<Project> => {
  return { ...projects };
};

export const useGetProjects = () => {
  const authenticatedGet = useAuthenticatedGet<RestPage<RestProject>, Page<Project>>(atadenAxiosInstance, restPageProjectConverter);
  return useCallback((pageSize: number, pageNumber: number) => authenticatedGet('/backoffice/api/v1/projects?pageSize=' + pageSize + '&pageNumber=' + pageNumber), [authenticatedGet]);
};

export const useGetProject = () => {
  const authenticatedGet = useAuthenticatedGet<RestProject, Project>(atadenAxiosInstance, restProjectConverter);
  return useCallback((projectId: string) => authenticatedGet('/backoffice/api/v1/projects/' + projectId), [authenticatedGet]);
};

export const useUpdateProjectStorageQuota = () => {
  const authenticatedPost = useAuthenticatedPost<void, void>(atadenAxiosInstance, () => {
  });
  return (projectId: string, storageQuotaLimit: number) => {
    return authenticatedPost('/backoffice/api/v1/projects/' + projectId + '/storage-quota', { spaceQuotaLimit: storageQuotaLimit });
  };
};

export const useRequestS3Certificate = () => {
  const authenticatedPost = useAuthenticatedPost<void, void>(atadenAxiosInstance, () => {
  });
  return (projectId: string) => {
    return authenticatedPost('/backoffice/api/v1/projects/' + projectId + '/request-s3-certificate', undefined);
  };
};
