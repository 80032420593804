import styled from 'styled-components';
import { Server } from '../domain/Server';
import { AtdnText, Colors, NoBellIcon, spacing } from '@ataden/design-system';
import React from 'react';
import { ServerStatusComponent } from './ServerStatusComponent';

type ServerComponentProps = {
  server: Server;

  onSelection: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;


const ServerContainer = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(0px, 1fr) 150px 150px 350px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

const ServerTitle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

export const ServerComponent = ({ server, onSelection }: ServerComponentProps) => {

  return (
        <Container>
            <ServerContainer onClick={onSelection}>
                <ServerStatusComponent status={server.status}/>
                <ServerTitle>
                    <AtdnText>{server.hostname}</AtdnText>
                    {!server.notificationEnabled && <NoBellIcon width={'15px'} color={Colors.Gray300}/>}
                </ServerTitle>
                <div>{server.datacenter}</div>
                <div>{server.ip}</div>
                <div>{server.serverId}</div>
            </ServerContainer>
        </Container>
  );
};
