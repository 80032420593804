import styled from 'styled-components';
import { Colors } from '@ataden/design-system';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 8px 0 8px 0;
    margin: 8px 0 8px 0;
    font-weight: 400;
    color: ${Colors.Brown900};
    font-size: 18px;

    &:hover {
        font-weight: 600;
    }
`;

type MenuItemProps = {
  title: string;
  currentUserRole: number;
  requiredRole?: number;

  onClick: () => void;
};

export const MenuItem = ({ title, currentUserRole, requiredRole, onClick }: MenuItemProps) => {

  if (requiredRole !== undefined && (currentUserRole & requiredRole) === 0) {
    return <></>;
  }

  return (
        <Container onClick={onClick}>
            {title}
        </Container>
  );
};
