import React, { useEffect, useState } from 'react';
import { useGetProjects } from '../repositories/ProjectRepository';
import styled from 'styled-components';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { Project } from '../domain/Project';
import { ProjectLine } from './components/ProjectLine';
import { ProjectPanel } from './components/ProjectPanel';

const ProjectHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(0px, 1fr) 300px 25px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const ProjectListPage = () => {

  const getProjects = useGetProjects();

  const [projectes, updateProjects] = useState<Project[]>([]);
  const [currentProject, updateCurrentProject] = useState<Project>();

  useEffect(() => {
    getProjects(200, 0)
      .then((page) => updateProjects(page.items));
  }, []);

  const onProjectClick = (project: Project) => {
    if (project.id === currentProject?.id) {
      updateCurrentProject(undefined);
    } else {
      updateCurrentProject(project);
    }
  };

  const onUpdateCurrentProject = (project: Project) => {
    updateProjects(projectes.map((b) => {
      if (b.id === project.id) {
        return { ...project };
      }
      return { ...b };
    }));

    updateCurrentProject({ ...project });
  };

  return (<div>
        <ProjectHeaderContainer>
            <div/>
            <AtdnText type="important">name</AtdnText>
            <AtdnText type="important">creation date</AtdnText>
        </ProjectHeaderContainer>
        {projectes.map((project) => <ProjectLine key={project.id} project={project}
                                                 onClick={() => onProjectClick(project)}/>)}
        {currentProject &&
            <ProjectPanel projectId={currentProject.id} onProjectUpdated={onUpdateCurrentProject}
                          onClose={() => updateCurrentProject(undefined)}/>}
    </div>);
};
