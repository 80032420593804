import styled from 'styled-components';
import { AtdnText, Colors, CrossIcon } from '@ataden/design-system';
import { Server } from '../domain/Server';
import { ServerStatusComponent } from './ServerStatusComponent';
import React from 'react';
import { ServerSticker } from './ServerSticker';
import { ServerCableSticker } from './ServerCableSticker';
import { ServicesComponent } from './ServicesComponent';
import { ServerDiskUsages } from './ServerDiskUsages';
import { ServerInfoComponent } from './ServerInfoComponent';
import { ServerSystemUsages } from './ServerSystemUsages';
import { ServerOptions } from './ServerOptions';

type ServerPanelProps = {
  server: Server;

  onServerUpdated: (server: Server) => void;
  onClose: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;

    padding: 16px;
    width: 100%;
    max-width: 800px;

    background-color: ${Colors.White};
    box-shadow: ${Colors.Gray300} -1px 1px 5px 1px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
`;
const CloseIconWrapper = styled.div`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TitleWithStatus = styled.div`
    display: flex;
    flex-direction: row;
`;


export const ServerPanel = ({ server, onServerUpdated, onClose }: ServerPanelProps) => {

  return (
        <Container>
            <Header>
                <TitleWithStatus>
                    <ServerStatusComponent status={server.status}/>
                    <AtdnText type="important">{server.hostname}</AtdnText>
                </TitleWithStatus>
                <CloseIconWrapper onClick={onClose}>
                    <CrossIcon color={Colors.Brown700}/>
                </CloseIconWrapper>
            </Header>
            <ServerInfoComponent server={server}/>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <ServicesComponent server={server}/>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">system:</AtdnText>
                        <ServerSystemUsages serverId={server.serverId}/>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">disks:</AtdnText>
                        <ServerDiskUsages serverId={server.serverId}/>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">options:</AtdnText>
                        <ServerOptions server={server} onServerUpdated={onServerUpdated}/>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">stickers:</AtdnText>
                        <ServerSticker serverId={server.serverId} serverName={server.hostname}/>
                        <AtdnText type="important">cable stickers:</AtdnText>
                        <ServerCableSticker serverName={server.hostname}/>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
        </Container>
  );
};
