import styled from 'styled-components';
import { AtdnText, Colors, RefreshIcon, spacing } from '@ataden/design-system';
import React from 'react';

type DnsStatusItemProps = {
  name: string;
  status: string;
  onUpdateRequested: (name: string) => void;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) 100px 25px;
    align-items: center;
    width: 100%;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

type DomainStatusProps = {
  color: string;
};

const DomainStatus = styled.div<DomainStatusProps>`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${(props) => props.color};
    margin-right: ${spacing(4)};
`;

const IconWrapper = styled.div`
    cursor: pointer;
`;


export const DnsStatusItem = ({ name, status, onUpdateRequested }: DnsStatusItemProps) => {
  const available = status === 'READY';
  const canBeUpdated = status === 'NONE';


  const onRenew = () => {
    onUpdateRequested(name);
  };

  return (
        <Container>
            <AtdnText>{name}</AtdnText>
            <StatusContainer>
                <DomainStatus color={available ? Colors.Green700 : Colors.Red500}/>
                {status}
            </StatusContainer>
            {canBeUpdated && (
                <IconWrapper onClick={onRenew}>
                    <RefreshIcon width="24px"/>
                </IconWrapper>
            )}
        </Container>

  );

};
