import styled from 'styled-components';
import { AtdnText } from '@ataden/design-system';

type SimulationItemProps = {
  label: string;
  value: string;
  color: string;
};

const Info = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
`;

export const SimulationItem = ({ label, value, color }: SimulationItemProps) => {

  return (
        <Info>
            <AtdnText scale={3} color={color}>{label}</AtdnText>
            <AtdnText color={color}>{value}</AtdnText>
        </Info>
  );
};

