import { Colors } from '@ataden/design-system';
import styled from 'styled-components';
import { SystemStatusComponent } from './SystemStatusComponent';

type ServerCpuUsageProps = {
  cpuUsage: number;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 15px minmax(0px, 1fr);
    column-gap: 16px;
    max-width: 600px;
`;

const computeCpuStatusColor = (value: number): string => {
  if (value > 90) {
    return Colors.Red500;
  } else if (value > 75) {
    return Colors.Yellow900;
  }
  return Colors.Green700;
};

export const ServerCpuUsage = ({ cpuUsage }: ServerCpuUsageProps) => {
  const cpuStatusColor = computeCpuStatusColor(cpuUsage);

  return (<Container>
        <SystemStatusComponent color={cpuStatusColor}/>
        <div>
            CPU : {cpuUsage}%
        </div>
    </Container>);
};
