import { Colors } from '@ataden/design-system';
import styled from 'styled-components';
import { SystemStatusComponent } from './SystemStatusComponent';
import { MemoryUsage } from '../domain/MemoryUsage';
import { byteConversion } from '../../common/ByteConvertion';

type ServerCpuUsageProps = {
  memoryUsage: MemoryUsage;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 15px minmax(0px, 1fr) 175px 175px;
    column-gap: 16px;
    max-width: 600px;
`;

const computeMemoryStatusColor = (memoryUsedRatio: number): string => {
  if (memoryUsedRatio > 0.9) {
    return Colors.Red500;
  } else if (memoryUsedRatio > 0.75) {
    return Colors.Yellow900;
  }
  return Colors.Green700;
};

export const ServerMemoryUsage = ({ memoryUsage }: ServerCpuUsageProps) => {
  const memoryUsedRatio = 1.0 - (memoryUsage.available / (memoryUsage.total < 1 ? 1 : memoryUsage.total));
  const memoryStatusColor = computeMemoryStatusColor(memoryUsedRatio);

  const memoryUsedPercentage = Math.ceil(memoryUsedRatio * 10000) / 100;

  return (<Container>
        <SystemStatusComponent color={memoryStatusColor}/>
        <div>
            Memory : {memoryUsedPercentage}%
        </div>
        <div>
            Available : {byteConversion(memoryUsage.available)}
        </div>
        <div>
            Total installed : {byteConversion(memoryUsage.total)}
        </div>
    </Container>);
};
