import { useEffect, useState } from 'react';
import { useGetServerCpuAndMemoryUsages } from '../repositories/ServerRepository';
import styled from 'styled-components';
import { CpuAndMemoryUsage } from '../domain/CpuAndMemoryUsage';
import { ServerCpuUsage } from './ServerCpuUsage';
import { ServerMemoryUsage } from './ServerMemoryUsage';

type ServerSystemUsagesProps = {
  serverId: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;


export const ServerSystemUsages = ({ serverId }: ServerSystemUsagesProps) => {

  const getGetServerCpuAndMemoryUsages = useGetServerCpuAndMemoryUsages();
  const [cpuAndMemoryUsage, updateCpuAndMemoryUsage] = useState<CpuAndMemoryUsage>();

  useEffect(() => {
    getGetServerCpuAndMemoryUsages(serverId)
      .then(updateCpuAndMemoryUsage);
  }, [serverId]);

  if (!cpuAndMemoryUsage) {
    return <></>;
  }

  return (
        <Container>
            <ServerCpuUsage cpuUsage={cpuAndMemoryUsage.cpuUsage}/>
            <ServerMemoryUsage memoryUsage={cpuAndMemoryUsage.memoryUsage}/>
        </Container>
  );
};

