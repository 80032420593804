import { AtdnSwitch, AtdnText } from '@ataden/design-system';
import { Server } from '../domain/Server';
import styled from 'styled-components';
import { useUpdateServerNotification } from '../repositories/ServerRepository';

type ServerOptionsProps = {
  server: Server;
  onServerUpdated: (server: Server) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

export const ServerOptions = ({ server, onServerUpdated }: ServerOptionsProps) => {

  const updateServerNotification = useUpdateServerNotification();

  const onNotificationChanged = (newValue: boolean) => {
    console.log('newValue', newValue);

    if (newValue != server.notificationEnabled) {

      updateServerNotification(server.serverId, newValue)
        .then(() => {
          onServerUpdated({ ...server, notificationEnabled: newValue });
        });
    }

  };

  return (
        <Container>
            <OptionContainer>
                <AtdnSwitch checked={server.notificationEnabled}
                            onChange={(evt) => onNotificationChanged(evt.target.checked)}/>
                <AtdnText>Push Slack notification on status change</AtdnText>
            </OptionContainer>
        </Container>
  );
};
