import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { RoutePath } from '../AuthenticatedRouter';
import { Loader } from '@ataden/design-system';
import { isUserBackOfficer } from '../users/domain/UserInfo';
import { useGetCurrentUser } from '../users/repositories/UserRepository';
import { User } from '../users/domain/User';

export const AuthenticatedLayout = () => {
  const [userLoaded, updateUserLoaded] = useState<User>();
  const { isLoading, isAuthenticated } = useAuth0();

  const getCurrentUser = useGetCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getCurrentUser()
      .then((userInfo) => {
        if (!isUserBackOfficer(userInfo.role)) {
          navigate(RoutePath.login);
          return;
        }

        updateUserLoaded(userInfo);
      });
  }, [isAuthenticated]);

  if (!isAuthenticated && !isLoading) {
    return <Navigate to={RoutePath.login}/>;
  }

  if (isLoading || userLoaded === undefined) {
    return <Loader scale={10}/>;
  }

  return (
        <Outlet/>
  );
};
