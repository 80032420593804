import React from 'react';
import styled from 'styled-components';
import { AppConfig } from './config/AppConfigProvider';
import { AppRouter } from './AppRouter';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

function App() {

  return (
        <AppConfig>
            <AppContainer>
                <AppRouter/>
            </AppContainer>
        </AppConfig>
  );
}

export default App;
