export const USER_ROLE_OPS = 0b00000001;
export const USER_ROLE_DEV = 0b00000010;
export const USER_ROLE_SUPPORT = 0b00000100;
export const USER_ROLE_SALE = 0b00001000;
export const USER_ROLE_FINANCE = 0b00010000;
export const USER_ROLE_MARKETING = 0b00100000;

export const USER_ROLE_ALL = USER_ROLE_MARKETING * 2 - 1;

export type User = {
  id: string;
  email: string;
  creationDate: number;
  role: number;
  licenseApprobationDate: number;
};
