import { AtdnText } from '@ataden/design-system';
import { Project } from '../../domain/Project';
import { InfoContainer, ItemContainer, LabelContainer } from '../../../common/components/PanelItem.shared';
import { DnsStatusItem } from './DnsStatusItem';
import { useRequestS3Certificate } from '../../repositories/ProjectRepository';

type DnsStatusProps = {
  project: Project;

  onProjectUpdated: (project: Project) => void;
};


export const DnsStatus = ({ project, onProjectUpdated }: DnsStatusProps) => {

  const requestS3Certificate = useRequestS3Certificate();

  const onUpdateRequested = (name: string) => {
    if (name === 'S3') {
      requestS3Certificate(project.id)
        .then(() => {
          onProjectUpdated({
            ...project, s3DomainStatus: 'REQUESTED',
          });
        });
    }
  };

  return (
        <ItemContainer>
            <InfoContainer>
                <LabelContainer>
                    <AtdnText type="important">domain:</AtdnText>
                </LabelContainer>
            </InfoContainer>
            <DnsStatusItem name="S3" status={project.s3DomainStatus} onUpdateRequested={onUpdateRequested}/>

        </ItemContainer>
  );
};
