import { atadenAxiosInstance, convertRestPageToDomain, Page, RestPage } from '../../common';
import { useAuthenticatedGet } from '../../common/AuthenticatedRestCall.hook';
import { User } from '../domain/User';
import { useGetStoreCurrentUser, useInitCurrentUser } from '../UserStore';

type RestUser = {
  id: string;
  email: string;
  creationDate: number;
  role: number;
  licenseApprobationDate: number;
};


const restUserConverter = (user: RestUser): User => {
  return { ...user };
};

const convertRestPageUserToDomain = (rest: RestPage<RestUser>) => convertRestPageToDomain<RestUser, User>(rest, restUserConverter);

export const useGetCurrentUser = () => {
  const protectedGet = useAuthenticatedGet<RestUser, User>(atadenAxiosInstance, restUserConverter);

  const initCurrentUser = useInitCurrentUser();
  const getCurrentUser = useGetStoreCurrentUser();

  const recursiveRequest = (tryCount: number, success: any, failure: any) => {
    protectedGet('/protected/api/v1/users/me')
      .then(user => {
        initCurrentUser(user);
        success(user);
      })
      .catch((err) => {
        if (tryCount < 5) {
          setTimeout(() => {
            recursiveRequest(tryCount + 1, success, failure);
          }, 2500);
        } else {
          failure(err);
        }
      });

  };

  const requestUserWithRetry = (): Promise<User> => {

    return new Promise<User>((success, failure) => {
      recursiveRequest(0, success, failure);
    });
  };

  return (): Promise<User> => {
    return new Promise<User>((success, failure) => {

      const currentUser = getCurrentUser();
      if (currentUser) {
        success(currentUser);
        return;
      }

      requestUserWithRetry()
        .then(user => {
          initCurrentUser(user);
          success(user);
        })
        .catch(failure);
    });
  };
};


export const useGetUsers = () => {
  const protectedGet = useAuthenticatedGet<RestPage<RestUser>, Page<User>>(atadenAxiosInstance, convertRestPageUserToDomain);

  return (pageNumber: number): Promise<Page<User>> => {
    return new Promise<Page<User>>((success, failure) => {

      protectedGet('/backoffice/api/v1/users?pageSize=100&pageNumber=' + pageNumber)
        .then(users => {
          success(users);
        })
        .catch(failure);
    });
  };
};

export const useFindUserById = () => {
  const protectedGet = useAuthenticatedGet<RestUser, User>(atadenAxiosInstance, restUserConverter);

  return (userId: string): Promise<User> => {
    return new Promise<User>((success, failure) => {

      protectedGet('/backoffice/api/v1/users/' + userId)
        .then(users => {
          success(users);
        })
        .catch(failure);
    });
  };
};

export const useSearchUser = () => {
  const protectedGet = useAuthenticatedGet<RestUser, User>(atadenAxiosInstance, restUserConverter);

  return (email: string): Promise<User> => {
    return new Promise<User>((success, failure) => {

      protectedGet('/backoffice/api/v1/users/search?email=' + email)
        .then(users => {
          success(users);
        })
        .catch(failure);
    });
  };
};
