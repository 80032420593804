import React, { createContext, ReactElement } from 'react';
import { AuthenticationProvider } from '../auth/config/AuthenticationProvider';
import { UserContextProvider } from '../users/UserProvider';

export interface ConfigInterface {

}

export const ConfigContext = createContext<Partial<ConfigInterface>>({});

export const AppConfigProvider: React.FC<ConfigInterface> = ({ children, ...config }) => {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

type AppConfigProps = {
  children: ReactElement;
};

export const AppConfig = ({ children }: AppConfigProps) => {

  return (
        <AuthenticationProvider>
            <UserContextProvider>
                {children}
            </UserContextProvider>
        </AuthenticationProvider>
  );
};
